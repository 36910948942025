// Global
import { useSelector } from 'react-redux';

// Components
import { ProductBlock } from '@components/WizardContentBlocks';
import { TextBlock } from '@components/WizardContentBlocks';
import { WizardTemplate } from '@components/WizardTemplate';
import { HomeboxContentWrapper } from '@components/WizardContentBlocks';

// Constants
import { WizardPaths } from '@constants/index';

// Images
import { Broadband } from '@images/icons';
import GoogleNestAudio from '@images/products/SH-hero-smart-home.webp';

// Redux
import { selectMove } from '@features/move/move';
import { selectMoveStages } from '@features/moveStages/moveStages';
import { MoveStepIds } from '@features/moveStages/moveStages.types';

// Utils
import { isBuyer } from '@utils/checkBuyerSeller';

export const BroadbandPage = () => {
  const { value: move } = useSelector(selectMove);
  const {
    value: { steps },
  } = useSelector(selectMoveStages);
  const isBuying = isBuyer(move.type);

  const step = steps.find(step => step.id === MoveStepIds.Broadband);

  const isComplete = step?.progress === 100;

  return (
    <>
      <WizardTemplate
        move={move}
        nextHref={`/wizard/${WizardPaths.getReady}`}
        prevHref={`/wizard/${WizardPaths.energy}`}
        totalStepCount={isBuying ? 11 : 10}
        index={isBuying ? 8 : 7}
        title={`${!isBuying ? 'Moving to a new home? ' : ''}Get your home connected`}
        icon={<Broadband height="57px" />}
        intro="Compare prices and get your home connected"
        children={
          <HomeboxContentWrapper type="broadband" step={step} moveId={move.id}>
            <HomeboxContentWrapper.Column>
              <ProductBlock
                title="Making home smarter"
                smallTitle
                product={{
                  title: 'Google Nest Audio',
                  image: GoogleNestAudio,
                  price: 89.99,
                  link: 'https://store.home.cc/products/google-nest-audio-with-google-assistant',
                }}
                optionalLink={{
                  text: 'View more in HomeStore',
                  href: 'https://store.home.cc/',
                }}
              />
            </HomeboxContentWrapper.Column>
            <HomeboxContentWrapper.Column>
              <TextBlock
                title="What to expect"
                smallTitle
                text="Make sure your property is connected from move in day so that you never miss a show or an all important Zoom meeting. Get market wide broadband deals from leading providers to cover your property."
              />
            </HomeboxContentWrapper.Column>
          </HomeboxContentWrapper>
        }
        isComplete={isComplete}
      />
    </>
  );
};
