// Global
import { useSelector } from 'react-redux';

// Components
import { BreakoutBox, ContentWrapper } from '@components/WizardContentBlocks';
import { TextBlock } from '@components/WizardContentBlocks';
import { WizardTemplate } from '@components/WizardTemplate';
import { ProductBlock } from '@components/WizardContentBlocks';
import { LinkButton } from '@components/Button';

// Constants
import { WizardPaths } from '@constants/index';

// Components
import { TaskList } from '@components/TaskList';

// Images
import { Insurance } from '@images/icons';
import RingProduct from '@images/products/product_ring-spotlight-cam.webp';
import InsuranceProvidersImg from '@images/InsuranceProviders.webp';
import Quotezone from '@images/quotezone.webp';

// Redux
import { selectMove } from '@features/move/move';
import { selectMoveStages } from '@features/moveStages/moveStages';
import { MoveStepIds } from '@features/moveStages/moveStages.types';

// utils
import { isBuyer } from '@utils/checkBuyerSeller';

const WhatToExpectStep = ({ id, title, text }: any) => (
  <div className="flex flex-col items-center gap-2 md:items-start">
    <span className="rounded-2xl bg-white px-3 py-1 text-sm font-semibold text-primary">
      STEP {id}
    </span>
    <h4 className="text-2xl text-white">{title}</h4>
    <p className="text-center text-white md:text-left">{text}</p>
  </div>
);

export const InsurancePage = () => {
  const { value: move } = useSelector(selectMove);
  const {
    value: { steps },
  } = useSelector(selectMoveStages);
  const isBuying = isBuyer(move.type);

  const step = steps.find(step => step.id === MoveStepIds.Insurance);
  const isComplete = step?.progress === 100;

  const whatToExpectContent = isBuying
    ? 'Before you can exchange, your need to make sure you have buildings insurance in place on your new property.'
    : "If you're purchasing a new home too you will need to make sure you have buildings insurance in place on your new property.";

  return (
    <>
      <WizardTemplate
        move={move}
        prevHref={`/wizard/${WizardPaths.signContracts}`}
        nextHref={`/wizard/${WizardPaths.exchangeContracts}`}
        totalStepCount={isBuying ? 11 : 10}
        index={isBuying ? 5 : 4}
        title={`${!isBuying ? 'Moving to a new home? ' : ''}Save on your Home Insurance`}
        intro="Even Home can't predict the future. So instead, it finds you the best cover from leading names."
        icon={<Insurance height="57px" />}
        children={
          <ContentWrapper
            leftColumn={
              <>
                <BreakoutBox>
                  <div className="flex flex-col items-center gap-10 py-2">
                    <h3 className="max-w-80 text-center text-2xl font-medium">
                      View quotes from over 50 UK insurance providers, including
                    </h3>
                    <img
                      src={InsuranceProvidersImg}
                      className="w-full max-w-[344px]"
                      alt="Swiftcover, M&S, Endsleigh Insurance, Legal & General, Tesco Bank and AXA"
                    />
                    <LinkButton
                      href="https://homecc.quotezone.co.uk/home/index.php?id=5624248ac079e49fdd6736ce310c25e8"
                      target="_blank"
                      customStyles={`!my-0`}
                    >
                      Compare Home Insurance
                    </LinkButton>
                    <img
                      src={Quotezone}
                      className="w-full max-w-[112px]"
                      alt="Provided by Quotezone.co.uk"
                    />
                  </div>
                </BreakoutBox>

                {step && (
                  <BreakoutBox transparentmobile>
                    <TaskList title="Track your to-do list" moveId={move.id} tasks={step?.tasks} />
                  </BreakoutBox>
                )}
              </>
            }
            rightColumn={
              <>
                <TextBlock title="What to expect" text={whatToExpectContent} />
                <div className="flex flex-col items-center gap-12 md:items-start">
                  <WhatToExpectStep
                    id="1"
                    title="Get an instant quote"
                    text="Answer a few simple questions and find out how little it could cost for your insurance. It only takes minutes."
                  />
                  <WhatToExpectStep
                    id="2"
                    title="Select the best deal"
                    text="Compare up to 50 leading providers and find a great deal the easy way. "
                  />
                </div>
                <ProductBlock
                  title="Home Security"
                  product={{
                    title: 'Ring Spotlight Cam - Battery',
                    image: RingProduct,
                    price: 179.99,

                    link: 'https://store.home.cc/products/ring-spotlight-cam-battery',
                  }}
                />
              </>
            }
          />
        }
        isComplete={isComplete}
      />
    </>
  );
};
