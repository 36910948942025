import { useSelector } from 'react-redux';

// Components
import { ContactCard } from '@components/ContactCard';
import { ContentWrapper } from '@components/WizardContentBlocks';
import { TextBlock } from '@components/WizardContentBlocks';
import { WizardTemplate } from '@components/WizardTemplate';
import { ConnectedPill } from '@components/ConnectedPill';
import { TaskList } from '@components/TaskList';
import { BreakoutBox } from '@components/WizardContentBlocks/BreakoutBox';

// Constants
import { WizardPaths } from '@constants/index';

// Images
import { Clipboard } from '@images/icons';

// Redux
import { selectMove } from '@features/move/move';
import { selectMoveStages } from '@features/moveStages/moveStages';
import { MoveStepIds } from '@features/moveStages/moveStages.types';

// Utils
import { Agencies, getAgencyStyling } from '@utils/agencyStyling';
import { isBuyer } from '@utils/checkBuyerSeller';

export const SignContractsPage = () => {
  const { value: move } = useSelector(selectMove);
  const {
    value: { steps },
  } = useSelector(selectMoveStages);
  const isBuying = isBuyer(move.type);

  const participant = move?.participants.find(
    participant => participant.type === 'CONVEYANCER'
  ) as IAgentParticipant;

  const step = steps.find(step => step.id === MoveStepIds.Contracts);

  const agency = getAgencyStyling(!!participant ? participant.agency.code : Agencies.DEFAULT);

  const whatToExpectContent = isBuying
    ? "Your solicitor will provide a draft of your contracts to review. Once you're happy with them it's time to sign - this is the point where you commit to buying your new home."
    : "Your solicitor will provide a draft of your contracts to review. Once you're happy with them it's time to sign - this is the point where your buyer commits to buying your home.";

  return (
    <>
      <WizardTemplate
        move={move}
        prevHref={`/wizard/${WizardPaths.conveyancing}`}
        nextHref={`/wizard/${WizardPaths.insurance}`}
        totalStepCount={isBuying ? 11 : 10}
        index={isBuying ? 3 : 2}
        title="Contracts"
        intro={
          !!participant && (
            <ConnectedPill companyName={participant.agency.name} image={agency.images.pill} />
          )
        }
        icon={<Clipboard height="62px" />}
        children={
          <ContentWrapper
            manualStepUserType={step?.tracking === 'MANUAL' ? 'conveyancer' : ''}
            percentageComplete={step?.progress || 0}
            leftColumn={
              <BreakoutBox transparentmobile>
                {!step ? (
                  <p>Something went wrong fetching your move data</p>
                ) : (
                  <TaskList moveId={move.id} tasks={step?.tasks} />
                )}
              </BreakoutBox>
            }
            rightColumn={
              <>
                <TextBlock title="What to expect" text={whatToExpectContent} />
                {!!participant && <ContactCard cardTitle="Your conveyancer" {...participant} />}
              </>
            }
          />
        }
        isComplete={step?.progress === 100}
        styling={{
          backgroundcolour: agency.colour,
        }}
      />
    </>
  );
};
