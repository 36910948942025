// Global
import { useSelector } from 'react-redux';

// Components
import { Store } from '@components/Store';
import { Concierge } from '@components/ConciergeBlock';

// Images
import { Services, StoreBag, UserHeadset } from '@images/icons';

// Redux
import { selectProfile } from '@features/profile/profile';

// Local
import { Intro } from '../common/Intro';
import { AllServices } from './AllServices';
import { convertAddressToSingleLine } from '@utils/convertAddress';
import { BroadbandTile } from './Broadband';

export const NonNetworkedDashboard = () => {
  const { value: profile } = useSelector(selectProfile);

  const firstName = profile?.customer.name.firstName;
  const title = firstName ? `Hi ${firstName}, welcome Home` : 'welcome home';

  const address = profile?.properties?.[0] ? convertAddressToSingleLine(profile.properties[0]) : '';
  const status = profile?.properties?.[0]?.status;
  const statusDescription =
    status === 'BUYER'
      ? 'Buying'
      : status === 'SELLER'
        ? 'Selling'
        : status === 'TENANCY'
          ? 'Renting'
          : undefined;

  const postcode = profile?.properties?.[0]?.postcode ?? '';

  const statusItems = [
    {
      title: 'Concierge',
      children: (
        <UserHeadset className="h-[42px] fill-white transition-all duration-300 group-hover:fill-brand" />
      ),
      href: '#home-team',
    },
    {
      title: 'Services',
      children: (
        <Services className=" -ml-1 h-[52px] fill-white transition-all duration-300 group-hover:fill-brand" />
      ),
      href: 'https://home.cc/services',
    },
    {
      title: 'Home Store',
      children: (
        <StoreBag className="h-[42px] fill-white transition-all duration-300 group-hover:fill-brand" />
      ),
      href: 'https://store.home.cc',
      target: '_blank',
    },
  ];

  return (
    <main className="pb-20">
      <Intro
        title={`${title} `}
        pillText={statusDescription}
        address={address}
        images={[]}
        postcode={postcode}
        statusItems={statusItems}
      />
      <Concierge />
      {!!postcode && <BroadbandTile postcode={postcode} />}
      <AllServices />
      <Store isBuying={false} isMoving={false} />
    </main>
  );
};
