// Global
import { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import axios from 'axios'; // Cant use customAxios as tracking headers rejected by postcodes.io
import L from 'leaflet';

// Images
import DefaultImage from '@images/default_image_05-2024.webp';
import icon from './icon.svg';

// Styles
import 'tailwindcss/tailwind.css';
import 'leaflet/dist/leaflet.css';
import { Spinner } from '@components/Spinner';

const customIcon = new L.Icon({
  iconUrl: icon,
  iconRetinaUrl: icon,
  iconSize: [40, 54], // Adjust the size as needed
  iconAnchor: [17, 54], // Adjust the anchor as needed
  popupAnchor: [0, -54],
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
  shadowSize: [54, 54],
  shadowAnchor: [15, 55],
});

interface MapProps {
  postcode: string;
}

const Map = ({ postcode }: MapProps) => {
  const [{ lat, lon }, setCoordinates] = useState<{ lat: number; lon: number }>({ lat: 0, lon: 0 });
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);

  const cleanPostcode = postcode.replace(/\s/g, '').toUpperCase();

  useEffect(() => {
    const fetchCoordinates = async () => {
      try {
        const response = await axios.get(`https://api.postcodes.io/postcodes/${cleanPostcode}`);
        const { latitude, longitude } = response.data.result;
        setCoordinates({ lat: latitude, lon: longitude });
        setLoaded(true);
      } catch (error) {
        setLoaded(true);
        setError(true);
      }
    };

    fetchCoordinates();
  }, [postcode, cleanPostcode]);

  if (!loaded) {
    return (
      <div className="relative flex h-full w-full items-center justify-center">
        <Spinner colour="black" size="medium" />
      </div>
    );
  }

  if (!!error) {
    return (
      <div
        style={{ backgroundImage: `url(${DefaultImage})` }}
        className="relative z-10 w-full bg-cover bg-center pb-[66.66%] md:mt-0 md:pb-0"
        data-testid="home-image"
      />
    );
  }

  return (
    <div className="z-0 h-full min-h-[340px]">
      <MapContainer center={[lat, lon]} zoom={15} className="h-full" zoomControl={false}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          // url="https://tiles.stadiamaps.com/tiles/outdoors/{z}/{x}/{y}{r}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker position={[lat, lon]} icon={customIcon} />
      </MapContainer>
    </div>
  );
};

export default Map;
