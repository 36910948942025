// Global
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// Components
import { Button } from '@components/Button';

export const NotFound = () => {
  return (
    <>
      <Helmet>
        <title>Page Not Found</title>
      </Helmet>
      <div className=" mx-auto mb-20 mt-10 flex w-full max-w-3xl flex-col items-center justify-center">
        <h1 className="mb-10 text-center text-5xl font-medium">Sorry, that page was not found.</h1>
        <Link to="/">
          <Button>Go Home</Button>
        </Link>
      </div>
    </>
  );
};
