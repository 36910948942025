// Global
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Components
import { Button, LinkButton } from '@components/Button';
import { Spinner } from '@components/Spinner';

// Hooks
import { HomeboxBroadbandProduct, useBroadbandComparison } from '@hooks/useBroadbandComparison';

// Images
import { Broadband } from '@images/icons';
import BundlesIcons from '@images/bundles_icons.svg';
import BundlesLogos from '@images/bundles_logos.webp';
import BundlesBackground from '@images/bundles_background.jpg';

// Redux
import { selectProfile } from '@features/profile/profile';

// utils
import { convertAddressToSingleLine } from '@utils/convertAddress';
import { formatSpeed } from '@utils/formatBroadbandSpeed';

// Local
import { ReactComponent as LandlineIcon } from './landline.svg';
import { ReactComponent as WifiIcon } from './wifi.svg';
import { Concierge } from '@components/ConciergeBlock';
import { NoAddressTemp } from './noAddressTemp';

const Attribute = ({ children, label }: { children: React.ReactNode; label: string }) => (
  <div className="flex flex-col items-center gap-1">
    {children}
    <span className="text-center text-gray-500">{label}</span>
  </div>
);

const RadioButton = ({ selected, onChange, label }: any) => {
  return (
    <div className="flex items-center gap-3">
      <input type="radio" className="hidden" checked={selected} onChange={onChange} />
      <div
        className={`h-5 w-5 rounded-full border-2 ${
          selected ? 'bg-gray-700' : 'bg-transparent'
        } cursor-pointer border-gray-700`}
        onClick={onChange}
      />
      <span className="font-medium text-gray-700">{label}</span>
    </div>
  );
};

const Product = ({
  broadband,
  contractLength,
  downloadLimit,
  downloadSpeed,
  landLine,
  monthlyCost,
  outlink,
  productName,
  supplierImage,
}: HomeboxBroadbandProduct) => {
  const { speed, unit } = formatSpeed(downloadSpeed);

  return (
    <div className="flex flex-col bg-white">
      <div className="flex flex-col items-center justify-between border-b border-gray-200 px-8 py-4 sm:flex-row">
        <div className="flex items-center gap-3">
          <div
            style={{ backgroundImage: `url(${supplierImage})` }}
            className="h-12 w-28 rounded-lg bg-contain bg-center bg-no-repeat"
          />
        </div>
        <h4 className="mb-4 mt-2 text-lg font-medium sm:my-0">{productName}</h4>
        <div className="flex items-center gap-5">
          {broadband && <WifiIcon />}
          {landLine && <LandlineIcon />}
        </div>
      </div>
      <div className="grid grid-cols-2 gap-x-2 gap-y-6 px-4 py-6 sm:grid-cols-4 md:grid-cols-5">
        <Attribute label="Average UK Speed">
          <span className="font-heading text-2xl font-medium">
            {speed}&nbsp;{unit}
          </span>
        </Attribute>
        <Attribute label="Downloads">
          <span className="font-heading text-2xl font-medium">
            {downloadLimit >= 5000000 ? 'Unlimited' : downloadLimit}
          </span>
        </Attribute>
        <Attribute label="Contract">
          <span className="font-heading text-2xl font-medium">{contractLength} Months</span>
        </Attribute>
        <Attribute label="Monthly Cost">
          <span className="font-heading text-2xl font-medium">£{monthlyCost} p/m</span>
        </Attribute>
        <div className="col-span-2 flex justify-center sm:col-span-4 md:col-span-1">
          <LinkButton href={outlink} target="_blank" customStyles="!my-0 !text-base">
            Choose deal
          </LinkButton>
        </div>
      </div>
    </div>
  );
};

export const ServicesBroadbandPage = () => {
  const { value: profile } = useSelector(selectProfile);
  const address = profile?.properties?.[0] ? convertAddressToSingleLine(profile.properties[0]) : '';
  const postcode = profile?.properties?.[0]?.postcode ?? '';

  const [pagination, setPagination] = useState({ start: 0, end: 25, page: 1 });
  const [speed, setSpeed] = useState(0);
  const [contractLength, setContractLength] = useState(0);

  const { data, error, loading } = useBroadbandComparison(
    postcode,
    pagination.start,
    pagination.end,
    { speed, contractLength }
  );

  const totalDeals = data?.totalCount ?? 0;
  const totalPages = Math.ceil(totalDeals / 25);

  const handlePageChange = (direction: number) => {
    const newPage = pagination.page + direction;
    setPagination({
      start: pagination.start + 25 * direction,
      end: pagination.end + 25 * direction,
      page: newPage,
    });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [pagination.page]);

  if (!postcode) return <NoAddressTemp />;

  return (
    <>
      <header className="mx-auto my-4 flex w-full max-w-3xl flex-col items-center justify-center gap-8 px-5 pb-12 pt-8">
        <h2 className="flex flex-col items-center gap-2 text-center text-3xl font-medium sm:flex-row md:text-4xl">
          <Broadband className="h-14 fill-brand sm:hidden" />
          <span>Here are your</span>
          <Broadband className="hidden h-14 fill-brand sm:block" />
          <span>broadband deals</span>
        </h2>
        <h3 className="text-center text-xl font-medium">{address}</h3>
        <p className="text-center">
          Compare plans and get your home connected. Set things up yourself below or speak to your
          Home concierge and we&apos;ll do the hard work for you.
        </p>
      </header>
      <main className="mx-auto mb-10 w-full max-w-7xl px-5">
        <div className="flex flex-col items-center justify-center gap-10 md:gap-14">
          {loading && (
            <div className="py-20">
              <Spinner colour="black" />
            </div>
          )}
          {error && <p>Something went wrong. Please try again later.</p>}

          {!!data && (
            <div className="flex w-full flex-col gap-10 lg:flex-row">
              <aside className="flex gap-10 lg:basis-[240px] lg:flex-col lg:pl-2">
                <div className="flex min-w-[200px] flex-col gap-3">
                  <span className="text-lg font-medium">Average speed</span>
                  <hr className="w-full border-gray-300" />
                  <RadioButton
                    selected={speed === 30}
                    onChange={() => (speed === 30 ? setSpeed(0) : setSpeed(30))}
                    label="30MB+"
                  />
                  <RadioButton
                    selected={speed === 60}
                    onChange={() => (speed === 60 ? setSpeed(0) : setSpeed(60))}
                    label="60MB+"
                  />
                  <RadioButton
                    selected={speed === 100}
                    onChange={() => (speed === 100 ? setSpeed(0) : setSpeed(100))}
                    label="100MB+"
                  />
                  <RadioButton
                    selected={speed === 999}
                    onChange={() => (speed === 999 ? setSpeed(0) : setSpeed(999))}
                    label="1GB+"
                  />
                </div>
                <div className="flex min-w-[200px] flex-col gap-3">
                  <span className="text-lg font-medium">Contract length</span>
                  <hr className="w-full border-gray-300" />
                  <RadioButton
                    selected={contractLength === 12}
                    onChange={() =>
                      contractLength === 12 ? setContractLength(0) : setContractLength(12)
                    }
                    label="12 Months"
                  />
                  <RadioButton
                    selected={contractLength === 18}
                    onChange={() =>
                      contractLength === 18 ? setContractLength(0) : setContractLength(18)
                    }
                    label="18 Months"
                  />
                  <RadioButton
                    selected={contractLength === 24}
                    onChange={() =>
                      contractLength === 24 ? setContractLength(0) : setContractLength(24)
                    }
                    label="24 Months"
                  />
                </div>
              </aside>
              <div className="flex flex-col gap-8">
                <div className="flex w-full items-center justify-between">
                  <p className="text-lg">
                    <span className="font-medium">{data.totalCount}</span> deals found
                  </p>
                  {totalPages > 1 && (
                    <p>
                      Page {pagination.page} of {totalPages}
                    </p>
                  )}
                </div>
                {data.products.length === 0 && (
                  <div className="flex w-full flex-col gap-5 py-16 text-center text-2xl font-medium">
                    No results found
                  </div>
                )}
                <div className="flex flex-col gap-8">
                  {data.products.map((product, index) => (
                    <Product key={`${product.productName}-${index}`} {...product} />
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
        {totalDeals > 25 && (
          <>
            {totalPages > 1 && (
              <p className="mt-10 block text-center md:hidden">
                Page {pagination.page} of {totalPages}
              </p>
            )}
            <div className="flex items-center justify-between gap-8 md:mt-8">
              <div className="mx-auto w-full max-w-60">
                <Button onClick={() => handlePageChange(-1)} disabled={pagination.page === 1}>
                  Previous
                </Button>
              </div>
              {totalPages > 1 && (
                <p className="hidden md:block">
                  Page {pagination.page} of {totalPages}
                </p>
              )}
              <div className="mx-auto w-full max-w-60">
                <Button
                  onClick={() => handlePageChange(1)}
                  disabled={pagination.page >= totalPages}
                >
                  Next
                </Button>
              </div>
            </div>
          </>
        )}
      </main>
      <section className="mx-auto flex max-w-7xl flex-col px-8 lg:px-10">
        <div className=" my-16 grid grid-cols-1 overflow-hidden rounded-3xl bg-white shadow-homeLarge lg:grid-cols-2">
          <div className="col-span-1 flex flex-col items-start gap-6 p-7 md:p-14">
            <img
              src={BundlesIcons}
              alt="Bundle icons"
              width={300}
              height={100}
              aria-hidden
              className="max-w-full"
            />
            <h3 className="text-2xl font-medium md:text-4xl">
              Save time and money with a Home bills bundle
            </h3>
            <p className=" text-lg">
              One payment, all your bills sorted. Create a bundle of all your bills to save more and
              simplify your home admin with just one easy bill to manage. You can also add your
              council tax and a TV package too!
            </p>
            <img
              src={BundlesLogos}
              alt="Bundle logos: Vodafone, Sky, Netflix, E-on, United Utilities"
              width={375}
              height={100}
              className="max-w-full"
            />
            <a
              href="https://app.homebox.co.uk/home/setup-account"
              target="_blank"
              className={`font-aktiv mt-auto inline-block rounded-2xl bg-black px-10 py-3 text-center font-semibold text-white hover:bg-gray-700 md:px-12 md:py-4`} rel="noreferrer"
            >
              Explore bundles
            </a>
          </div>
          <div
            style={{
              backgroundImage: `url(${BundlesBackground})`,
            }}
            className="col-span-1 bg-cover bg-center bg-no-repeat pb-[60%]"
          />
        </div>
      </section>
      <Concierge />
    </>
  );
};
