// Global
import { useSelector } from 'react-redux';

// Components
import { ProductBlock } from '@components/WizardContentBlocks';
import { TextBlock } from '@components/WizardContentBlocks';
import { WizardTemplate } from '@components/WizardTemplate';
import { HomeboxContentWrapper } from '@components/WizardContentBlocks';

// Constants
import { WizardPaths } from '@constants/index';

// Images
import { Bulb } from '@images/icons';
import GoogleNestThermostat from '@images/products/product_google-nest.webp';

// Redux
import { MoveStepIds } from '@features/moveStages/moveStages.types';
import { selectMove } from '@features/move/move';
import { selectMoveStages } from '@features/moveStages/moveStages';

// Utils
import { isBuyer } from '@utils/checkBuyerSeller';

export const EnergyPage = () => {
  const { value: move } = useSelector(selectMove);
  const {
    value: { steps },
  } = useSelector(selectMoveStages);
  const isBuying = isBuyer(move.type);

  const step = steps.find(step => step.id === MoveStepIds.Energy);

  const isComplete = step?.progress === 100;

  return (
    <>
      <WizardTemplate
        move={move}
        prevHref={`/wizard/${WizardPaths.removals}`}
        nextHref={`/wizard/${WizardPaths.broadband}`}
        totalStepCount={isBuying ? 11 : 10}
        index={isBuying ? 7 : 6}
        title={`${!isBuying ? 'Moving to a new home? ' : ''}Save on your energy bills`}
        intro="Home can help find the perfect tariff for your place. And even makes switching a breeze."
        icon={<Bulb height="57px" />}
        children={
          <HomeboxContentWrapper type="energy" step={step} moveId={move.id}>
            <HomeboxContentWrapper.Column>
              <ProductBlock
                title="Energy saving smart devices"
                smallTitle
                product={{
                  title: 'GOOGLE Nest Thermostat',
                  image: GoogleNestThermostat,
                  price: 219.99,
                  link: 'https://store.home.cc/products/nest-learning-thermostat',
                }}
              />
            </HomeboxContentWrapper.Column>
            <HomeboxContentWrapper.Column>
              <TextBlock
                title="What to expect"
                smallTitle
                text="Find the right tariff for you and switch, ready for your move in. Now is a great time to start thinking about saving on your bills too."
              />
            </HomeboxContentWrapper.Column>
          </HomeboxContentWrapper>
        }
        isComplete={isComplete}
      />
    </>
  );
};
