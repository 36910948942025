// Global
import { useSelector } from 'react-redux';

// Components
import { TaskList } from '@components/TaskList';
import { ContactCard } from '@components/ContactCard';
import { ConnectedPill } from '@components/ConnectedPill';
import { ContentWrapper } from '@components/WizardContentBlocks';
import { TextBlock } from '@components/WizardContentBlocks';
import { WizardTemplate } from '@components/WizardTemplate';
import { BreakoutBox } from '@components/WizardContentBlocks';

// Constants
import { WizardPaths } from '@constants/index';

// Images
import { Complete } from '@images/icons';

// Redux
import { selectMove } from '@features/move/move';
import { selectMoveStages } from '@features/moveStages/moveStages';
import { MoveStepIds } from '@features/moveStages/moveStages.types';

// Utils
import { convertPafToSingleLine } from '@utils/convertPaf';
import { Agencies, getAgencyStyling } from '@utils/agencyStyling';
import { isBuyer } from '@utils/checkBuyerSeller';

export const CompletePage = () => {
  const { value: move } = useSelector(selectMove);
  const {
    value: { steps },
  } = useSelector(selectMoveStages);
  const isBuying = isBuyer(move.type);

  const participant = move?.participants.find(
    participant => participant.type === 'CONVEYANCER'
  ) as IAgentParticipant;

  const agency = getAgencyStyling(!!participant ? participant.agency.code : Agencies.DEFAULT);

  const step = steps.find(step => step.id === MoveStepIds.Complete);

  const conveyancerText = !!participant ? participant.agency.name : 'your conveyancer';

  const sentenceOne = isBuying
    ? 'The big day! Your moment to finally relax as the conveyancing process has finished and you are the new owner of '
    : 'The big day! Your moment to finally relax as the conveyancing process has finished and you move out of ';

  const sentenceTwo = isBuying
    ? `Ownership of the property is transferred to you, your funds are transferred and you get the long awaited keys. After that ${conveyancerText} will then register the change of ownership with the Land Registry.`
    : `Ownership of the property is transferred along with funds and you can hand over the keys. After that ${conveyancerText} will then register the change of ownership with the Land Registry.`;

  return (
    <>
      <WizardTemplate
        move={move}
        prevHref={`/wizard/${WizardPaths.getReady}`}
        totalStepCount={isBuying ? 11 : 10}
        index={isBuying ? 10 : 9}
        title="Complete"
        icon={<Complete width="130px" />}
        intro={
          <>
            <p className="text-center">
              {sentenceOne}
              {convertPafToSingleLine(move.address) || 'the property'}!
            </p>
            <p className="text-center">{sentenceTwo}</p>
            {!!participant && (
              <ConnectedPill companyName={participant.agency.name} image={agency.images.pill} />
            )}
          </>
        }
        children={
          <ContentWrapper
            percentageComplete={step?.progress || 0}
            leftColumn={
              <BreakoutBox transparentmobile>
                {!step ? (
                  <p className="p-5">Something went wrong fetching your move data</p>
                ) : (
                  <TaskList moveId={move.id} tasks={step?.tasks} />
                )}
              </BreakoutBox>
            }
            rightColumn={
              <>
                <TextBlock
                  title="What to expect"
                  text="This is where it becomes official. Your solicitor and the seller's solicitor will swap signed copies of the contract. This makes it a legally-binding contract and you really start to celebrate."
                />
                {!!participant && <ContactCard cardTitle="Your conveyancer" {...participant} />}
              </>
            }
          />
        }
        isComplete={step?.progress === 100}
        styling={{
          backgroundcolour: agency.colour,
        }}
      />
    </>
  );
};
