import { Broadband } from '@images/icons';
import {
  HomeboxBroadbandProduct,
  useBroadbandComparison,
} from '../../../../hooks/useBroadbandComparison';
import { LinkButton } from '@components/Button';
import { Spinner } from '@components/Spinner';
import React from 'react';
import { formatSpeed } from '@utils/formatBroadbandSpeed';

interface BroadbandTileProps {
  postcode: string;
}

const Deal = React.memo(
  ({ supplierImage, downloadSpeed, effectiveMonthlyCost, outlink }: HomeboxBroadbandProduct) => {
    const { speed, unit } = formatSpeed(downloadSpeed);

    return (
      <div className="flex flex-col gap-6">
        <div className="flex flex-col items-center justify-center gap-6 sm:flex-row">
          <div
            style={{ backgroundImage: `url(${supplierImage})` }}
            className=" h-14 w-28 rounded-lg bg-contain bg-center bg-no-repeat sm:h-16 lg:w-32"
          />
          <div className="flex items-end gap-1 font-medium leading-none">
            <span className="-mb-0.5 text-[30px] md:-mb-1 md:text-[36px]">{speed}</span>
            {unit}
          </div>
          <div className="flex items-end gap-1 font-medium leading-none">
            <span className="-mb-0.5 text-[30px] md:-mb-1 md:text-[36px]">
              £{effectiveMonthlyCost}
            </span>
            Per month
          </div>
        </div>
        <div className="flex w-full justify-center md:justify-end">
          <LinkButton href={outlink} target="_blank" customStyles="!my-0">
            Get deal
          </LinkButton>
        </div>
      </div>
    );
  }
);

export const BroadbandTile: React.FC<BroadbandTileProps> = ({ postcode }) => {
  const { data, error, loading } = useBroadbandComparison(postcode, 0, 250);

  if (loading)
    return (
      <div className="flex w-full items-center justify-center px-8 pb-20 pt-32">
        <Spinner colour="black" />
      </div>
    );

  if (error || !data) return null;

  const { cheapestProduct, fastestProduct } = data.recommendations;
  const { speed: fastestSpeed, unit: fastestUnit } = formatSpeed(fastestProduct.downloadSpeed);

  return (
    <section className="mx-auto flex max-w-7xl flex-col items-center gap-8 px-8 font-heading lg:px-10">
      <div className="mt-16 flex w-full flex-col justify-center gap-8 overflow-hidden rounded-3xl bg-white px-8 pb-12 pt-8 shadow-homeLarge lg:px-12">
        <div className="flex w-full flex-col items-center justify-center gap-3">
          <h2 className="flex flex-col items-center justify-center gap-4 text-center text-3xl font-medium md:flex-row">
            <Broadband className="h-14 fill-primary" />
            <span>
              Get up to {fastestSpeed}&nbsp;
              <span className="text-xl">{fastestUnit}</span> broadband!
            </span>
          </h2>
          <h3 className="text-center text-2xl font-medium">
            We&apos;ve checked the market for you. Compare plans and get your home connected.
          </h3>
        </div>
        <div className="grid grid-cols-1 gap-12 md:grid-cols-2 md:gap-8">
          <div className="flex flex-col items-center gap-2 md:items-start">
            <h4 className="text-xl font-medium">Your cheapest deal:</h4>
            <Deal {...cheapestProduct} />
          </div>
          <div className="flex flex-col items-center gap-2 md:items-start">
            <h4 className="text-xl font-medium">Your fastest deal:</h4>
            <Deal {...fastestProduct} />
          </div>
        </div>
      </div>
    </section>
  );
};
