// Global
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

// Components
import { ContactCard } from '@components/ContactCard';
import { HomeRunnerContactCard } from '@components/ContactCard';
import { Heading } from '@components/Heading';

// Images
import { Family } from '@images/icons';

// Redux
import { selectMove } from '@features/move/move';

// Local
import { isAgentParticipant } from '@utils/participantTypeChecks';
import { isMoveActive } from '@utils/checkMoveStatus';

export const MyTeam = () => {
  const { value: move } = useSelector(selectMove);

  const filteredParticipants = useMemo(
    () => move.participants.filter(p => isAgentParticipant(p)) as IAgentParticipant[],
    [move.participants]
  );

  const isMoving = useMemo(() => isMoveActive(move.status), [move.status]);

  const team = isMoving ? 'Move Team' : 'Home Team';

  return (
    <div className="my-team mx-auto flex w-full max-w-7xl flex-col gap-20 px-5 pb-20 pt-10">
      <div className="flex flex-col items-center justify-center gap-3">
        <Heading icon={<Family className="h-16 w-16 fill-primary lg:h-12 lg:w-12" />}>
          My {team}
        </Heading>
        <h2 className="text-center text-2xl font-medium text-primary md:text-3xl">
          Everyone involved in your move in one helpful place
        </h2>
      </div>
      <div
        className="flex w-full flex-wrap justify-center gap-14 md:gap-12"
        data-testid="contact-cards-container"
      >
        {!isMoving && <HomeRunnerContactCard data-testid="home-runner-contact-card" />}
        {filteredParticipants.map(participant => (
          <ContactCard
            key={participant.id}
            chipText={`Your ${participant.type.toLowerCase()}`}
            {...participant}
          />
        ))}
        {isMoving && <HomeRunnerContactCard data-testid="home-runner-contact-card" />}
      </div>
    </div>
  );
};
