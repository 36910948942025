// Global
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Div100vh, { use100vh } from 'react-div-100vh';

// Components
import { CoadjutePill } from '@components/CoadjutePill';
import { Heading } from '@components/Heading';
import { Sidebar } from '@components/Messaging/Sidebar';
import { ConditionalWrapper } from '@components/ConditionalWrapper';
import { Room } from '@components/Messaging/Room';
import { ErrorMessage } from '@components/ErrorMessage';

// Hooks
import { useMediaQuery } from '@hooks/useMediaQuery';

// Images
import { Chat } from '@images/icons';

// Utils
import { saveMessageRoomViewingTime } from '@utils/newMessageUtils';

// Redux
import { AppThunkDispatch } from 'src/App';
import { selectMessageRooms } from '@features/messageRooms/messageRooms';
import { fetchMessages } from '@features/messages/messages.thunks';
import { selectMoveId } from '@features/move/move';
import { selectMessagesForRoom, selectMessagesState } from '@features/messages/messages';

export const MESSAGES_HEADER_HEIGHT = 208; // Used in messaging to calculate the height of the message stream

export const Messaging = () => {
  const [activeRoomIndex, setActiveRoomIndex] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const height = use100vh();

  const messageRooms = useSelector(selectMessageRooms);
  const moveId = useSelector(selectMoveId);
  const messagesState = useSelector(selectMessagesState);

  const roomId = messageRooms.length > 0 ? messageRooms[activeRoomIndex].id : '';

  const groupedMessages = useSelector(selectMessagesForRoom(roomId));

  const dispatch = useDispatch<AppThunkDispatch>();
  const { IS_MOBILE } = useMediaQuery();

  const fetch = () => {
    dispatch(fetchMessages({ moveId, roomId }));
  };

  const handleRoomChange = (i: number) => {
    setActiveRoomIndex(i);
    if (IS_MOBILE) {
      setIsActive(!isActive);
    }
  };

  // Check if the rooms have changed and reset the active room index if so as chat will be moving to top
  const prevRoomsRef = useRef<any>();
  useEffect(() => {
    const prevRooms = prevRoomsRef.current;
    if (prevRooms !== messageRooms) {
      setActiveRoomIndex(0);
    }
    prevRoomsRef.current = messageRooms;
  }, [messageRooms]);

  // Setup 30 second poll for new messages
  useEffect(() => {
    fetch();

    saveMessageRoomViewingTime(roomId);

    const intervalId = setInterval(fetch, 30000);

    return () => clearInterval(intervalId);
  }, [activeRoomIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="flex flex-col items-center justify-center">
      <header
        className="box-border flex w-full flex-col items-center justify-center gap-6 px-5 py-12"
        style={{ height: `${MESSAGES_HEADER_HEIGHT}px` }}
      >
        <Heading icon={<Chat className="h-12 w-12 fill-primary lg:h-10 lg:w-10" />}>
          Messages
        </Heading>
        <CoadjutePill clear />
      </header>

      {messageRooms.length === 0 ? (
        <div className="mx-auto max-w-xl px-10 py-20 md:py-48">
          <ErrorMessage includeHomeRunnerContact>
            An error occurred fetching Message Rooms.
          </ErrorMessage>
        </div>
      ) : (
        <div className="mx-auto grid w-full max-w-[1800px] grid-cols-1 border border-[rgba(0,0,0,0.2)] md:grid-cols-3 xl:shadow-2xl">
          <Sidebar
            rooms={messageRooms}
            activeRoomIndex={activeRoomIndex}
            handleRoomClick={handleRoomChange}
          />
          <ConditionalWrapper
            key={`chat-${activeRoomIndex}`}
            condition={IS_MOBILE ? isActive : false}
            wrapper={children => {
              return (
                <Div100vh
                  key={`chat-${activeRoomIndex}`}
                  className="fixed left-0 top-0 z-[99999999999999999] w-full bg-white"
                >
                  {children}
                </Div100vh>
              );
            }}
          >
            <Room
              room={messageRooms[activeRoomIndex]}
              formattedMessages={groupedMessages}
              messagesState={messagesState}
              moveId={moveId}
              isActive={IS_MOBILE ? isActive : true}
              closeChat={() => setIsActive(false)}
              height={height}
            />
          </ConditionalWrapper>
        </div>
      )}
    </div>
  );
};
