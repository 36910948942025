// Global
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

// Components
import { ContactCard } from '@components/ContactCard';
import { ContentWrapper } from '@components/WizardContentBlocks';
import { WizardTemplate } from '@components/WizardTemplate';
import { ConnectedPill } from '@components/ConnectedPill';
import { TaskList } from '@components/TaskList';
import { BreakoutBox } from '@components/WizardContentBlocks/BreakoutBox';

// Constants
import { WizardPaths } from '@constants/index';

// Images
import { MapPin, TickIsolated } from '@images/icons';

// Redux
import { selectMove } from '@features/move/move';
import { selectMoveStages } from '@features/moveStages/moveStages';
import { MoveStepIds } from '@features/moveStages/moveStages.types';

// Utils
import { convertPafToSingleLine } from '@utils/convertPaf';
import { convertIPriceToString } from '@utils/convertIPriceToString';
import { Agencies, getAgencyStyling } from '@utils/agencyStyling';
import { isBuyer } from '@utils/checkBuyerSeller';
import { BuyerProtectionSignpost } from '@components/Signpost';
import { useIsTaskCompleted } from '@hooks/useTaskChecks';

export const FindAPropertyPage = () => {
  const { value: move } = useSelector(selectMove);
  const {
    value: { steps },
  } = useSelector(selectMoveStages);
  const isBuying = isBuyer(move.type);

  // Hide signpost if the task is completed as insurance no longer available
  const hideSignpost = useIsTaskCompleted(MoveStepIds.Contracts, 'DRAFT_CONTRACT_RECEIVED');

  if (!isBuying) return <Navigate to={`/wizard/${WizardPaths.saleAgreed}`} />;

  const participant = move?.participants.find(
    participant => participant.type === 'AGENT'
  ) as IAgentParticipant;

  const step = steps.find(step => step.id === MoveStepIds.FindAProperty);

  const agency = getAgencyStyling(!!participant ? participant.agency.code : Agencies.DEFAULT);

  return (
    <>
      <WizardTemplate
        move={move}
        totalStepCount={isBuying ? 11 : 10}
        nextHref={`/wizard/${WizardPaths.finance}`}
        index={0}
        isComplete={step?.progress === 100}
        title={'Find a property'}
        intro={
          <div className="flex flex-col items-center gap-1">
            <h2 className="text-center text-xl font-medium">
              {convertPafToSingleLine(move.address)}
            </h2>
            {!!move.property.price && (
              <h3 className="flex gap-5 text-xl font-medium">
                <span>{convertIPriceToString(move.property.price)}</span>
                <span className="flex items-center gap-1 text-lg">
                  <TickIsolated className="h-[19px] fill-primary" aria-hidden /> Sale agreed
                </span>
              </h3>
            )}
            {!!participant && (
              <ConnectedPill companyName={participant.agency.name} image={agency.images.pill} />
            )}
          </div>
        }
        icon={<MapPin height="65px" className=" fill-primary" />}
        styling={{
          backgroundcolour: agency.colour,
        }}
        children={
          <ContentWrapper
            percentageComplete={step?.progress || 0}
            leftColumn={
              <>
                {!hideSignpost && <BuyerProtectionSignpost />}
                <BreakoutBox transparentmobile>
                  {!step ? (
                    <p className="p-5">Something went wrong fetching your move data</p>
                  ) : (
                    <TaskList moveId={move.id} tasks={step?.tasks} />
                  )}
                </BreakoutBox>
              </>
            }
            rightColumn={!!participant && <ContactCard cardTitle="Your agent" {...participant} />}
          />
        }
      />
    </>
  );
};
