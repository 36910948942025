// Global
import { useSelector } from 'react-redux';

// Components
import { ContentWrapper } from '@components/WizardContentBlocks';
import { TextBlock } from '@components/WizardContentBlocks';
import { WizardTemplate } from '@components/WizardTemplate';
import { ProductBlock } from '@components/WizardContentBlocks';
import { BreakoutBox } from '@components/WizardContentBlocks';

// Constants
import { WizardPaths } from '@constants/index';

// Images
import { Removals } from '@images/icons';
import AnyvanLogo from '@images/AnyvanLogo.svg';
import PickfordsLogo from '@images/PickfordsLogo.webp';
import ProductRemovalPack from '@images/products/product_removal-pack.webp';

// Redux
import { selectMove } from '@features/move/move';
import { selectMoveStages } from '@features/moveStages/moveStages';
import { MoveStepIds } from '@features/moveStages/moveStages.types';

// Utils
import { isBuyer } from '@utils/checkBuyerSeller';
import { TaskList } from '@components/TaskList';

export const RemovalsPage = () => {
  const { value: move } = useSelector(selectMove);
  const {
    value: { steps },
  } = useSelector(selectMoveStages);
  const isBuying = isBuyer(move.type);

  const step = steps.find(step => step.id === MoveStepIds.Removals);

  const isComplete = step?.progress === 100;

  return (
    <>
      <WizardTemplate
        move={move}
        prevHref={`/wizard/${WizardPaths.exchangeContracts}`}
        nextHref={`/wizard/${WizardPaths.energy}`}
        totalStepCount={isBuying ? 11 : 10}
        index={isBuying ? 6 : 5}
        title={`${!isBuying ? 'Moving to a new home? ' : ''}Book your home removals`}
        intro="Home's got lorry loads of choice. Pick one of its reliable partners, and book them in. Easy."
        icon={<Removals width="70px" />}
        children={
          <ContentWrapper
            leftColumn={
              <>
                <BreakoutBox>
                  <div className="flex flex-col items-center gap-8 py-2">
                    <h3 className="text-center text-2xl font-medium">
                      Hold tight... we're busy getting the best quotes for you.
                    </h3>
                    <p className="text-center text-lg">
                      There's nothing to do right now: our team of Home Runners are speaking to our
                      move partners to get you the best deal possible.
                    </p>
                    <p className="text-center text-lg">
                      We will let you know once your removals quotes are in.
                    </p>
                    <div className="flex flex-col items-center">
                      <p className="text-center text-lg font-medium">Our partners</p>
                      <div className="flex items-center gap-6">
                        <img
                          src={AnyvanLogo}
                          className="w-[120px]"
                          alt="AnyVan Logo"
                          role="presentation"
                        />
                        <img
                          src={PickfordsLogo}
                          className="-mt-[1px] w-[128px]"
                          alt="Pickfords Logo"
                          role="presentation"
                        />
                      </div>
                    </div>
                  </div>
                </BreakoutBox>
                {step && (
                  <BreakoutBox transparentmobile>
                    <TaskList title="Track your to-do list" moveId={move.id} tasks={step?.tasks} />
                  </BreakoutBox>
                )}
              </>
            }
            rightColumn={
              <>
                <ProductBlock
                  title="Need packing supplies?"
                  product={{
                    title: '1-2 Bedroom Removal Pack',
                    image: ProductRemovalPack,
                    price: 46.99,
                    link: 'https://store.home.cc/products/1-2-bedroom-removal-pack-25-boxes',
                  }}
                />
                <TextBlock
                  title="What to expect"
                  text="Make your move hassle-free with professional moving services. Our reliable partners offer simple 'man and van' services through to a white glove approach with everything packed for you and unpacked in your new home."
                />
              </>
            }
          />
        }
        isComplete={isComplete}
      />
    </>
  );
};
