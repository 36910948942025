// Global
import { useSelector } from 'react-redux';

// Components
import { ContentWrapper } from '@components/WizardContentBlocks';
import { TextBlock } from '@components/WizardContentBlocks';
import { WizardTemplate } from '@components/WizardTemplate';
import { BreakoutBox } from '@components/WizardContentBlocks';
import { Store } from '@components/Store';

// Constants
import { WizardPaths } from '@constants/index';

// Images
import { Chat, Clock } from '@images/icons';

// Redux
import { selectMove } from '@features/move/move';
import { selectMoveStages } from '@features/moveStages/moveStages';
import { MoveStepIds } from '@features/moveStages/moveStages.types';

// Utils
import { isBuyer } from '@utils/checkBuyerSeller';
import { TaskList } from '@components/TaskList';

export const GetMoveReadyPage = () => {
  const { value: move } = useSelector(selectMove);
  const {
    value: { steps },
  } = useSelector(selectMoveStages);
  const isBuying = isBuyer(move.type);

  const step = steps.find(step => step.id === MoveStepIds.GetMoveReady);

  const isComplete = step?.progress === 100;

  return (
    <>
      <WizardTemplate
        move={move}
        prevHref={`/wizard/${WizardPaths.broadband}`}
        nextHref={`/wizard/${WizardPaths.complete}`}
        totalStepCount={isBuying ? 11 : 10}
        index={isBuying ? 9 : 8}
        title="You're nearly there. Let's get you ready for your move"
        intro="Our Home Runners are here to get you move ready. Switch your Council Tax, make sure your old mail finds your new doormat and take care of odd jobs like cleaning."
        icon={<Clock height="57px" className="fill-primary" />}
        children={
          <ContentWrapper
            leftColumn={
              <>
                <BreakoutBox>
                  <h4 className="text-center text-3xl font-medium">Get move ready</h4>
                  <p>Our team of Home Runners are here to help take care of any remaining jobs:</p>
                  <ul className="box-border flex w-full flex-col gap-5 px-5">
                    {[
                      'Utilities',
                      'Council Tax',
                      'Mail Forwarding',
                      'Cleaning',
                      'Furniture assembly',
                      'Odd jobs',
                      'Storage',
                    ].map((item, index) => (
                      <li key={`job-${index}`} className="flex w-full items-center gap-3">
                        <span className="h-2 w-2 rounded-full bg-primary" /> {item}
                      </li>
                    ))}
                  </ul>
                </BreakoutBox>
                {step && (
                  <BreakoutBox transparentmobile>
                    <TaskList title="Track your to-do list" moveId={move.id} tasks={step?.tasks} />
                  </BreakoutBox>
                )}
              </>
            }
            rightColumn={
              <>
                <TextBlock
                  title="How can we help"
                  text="Now that you've exchanged, it's time to take care of all the important little jobs involved in your move. From secure storage and cleaners to new tech for your next home; we've got you covered."
                />
                <div className="flex flex-col gap-5">
                  <p className="text-white">
                    <strong>Need a helping hand?</strong> Our team of Home Runners are here to help.
                  </p>
                  <p
                    className="flex cursor-pointer items-center gap-5 text-white underline hover:no-underline"
                    onClick={() => window.LiveChatWidget.call('maximize')}
                    role="button"
                    aria-label="Start a live chat"
                    tabIndex={0}
                    onKeyDown={e => {
                      if (e.key === 'Enter') window.LiveChatWidget.call('maximize');
                    }}
                  >
                    <span className="flex h-[55px] w-[60px] items-center justify-center rounded-[50%] bg-white shadow-md shadow-[rgba(0,0,0,0.5)]">
                      <Chat className="h-8 w-8 fill-primary" data-testid="chat-icon" aria-hidden />
                    </span>
                    <span>Start a live chat</span>
                  </p>
                </div>
              </>
            }
          />
        }
        isComplete={isComplete}
        additionalContent={<Store isBuying={isBuying} isMoving isNetworkUser />}
      />
    </>
  );
};
