// Global
import { useSelector } from 'react-redux';

// Components
import { TaskList } from '@components/TaskList';
import { ContactCard } from '@components/ContactCard';
import { ConnectedPill } from '@components/ConnectedPill';
import { ContentWrapper } from '@components/WizardContentBlocks';
import { TextBlock } from '@components/WizardContentBlocks';
import { WizardTemplate } from '@components/WizardTemplate';
import { BreakoutBox } from '@components/WizardContentBlocks';
import { WizardPaths } from '@constants/index';

// Images
import { Payment } from '@images/icons';

// Redux
import { selectMove } from '@features/move/move';
import { selectMoveStages } from '@features/moveStages/moveStages';
import { MoveStepIds } from '@features/moveStages/moveStages.types';

// Utils
import { Agencies, getAgencyStyling } from '@utils/agencyStyling';
import { isBuyer } from '@utils/checkBuyerSeller';

export const ExchangeContractsPage = () => {
  const { value: move } = useSelector(selectMove);
  const {
    value: { steps },
  } = useSelector(selectMoveStages);
  const isBuying = isBuyer(move.type);

  const participant = move?.participants.find(
    participant => participant.type === 'CONVEYANCER'
  ) as IAgentParticipant;

  const step = steps.find(step => step.id === MoveStepIds.Exchange);

  const agency = getAgencyStyling(!!participant ? participant.agency.code : Agencies.DEFAULT);

  return (
    <>
      <WizardTemplate
        move={move}
        prevHref={`/wizard/${WizardPaths.insurance}`}
        nextHref={`/wizard/${WizardPaths.removals}`}
        totalStepCount={isBuying ? 11 : 10}
        index={isBuying ? 4 : 3}
        title="Exchange"
        intro={
          !!participant && (
            <ConnectedPill companyName={participant.agency.name} image={agency.images.pill} />
          )
        }
        icon={<Payment width="58px" />}
        children={
          <ContentWrapper
            manualStepUserType={step?.tracking === 'MANUAL' ? 'conveyancer' : ''}
            percentageComplete={step?.progress || 0}
            leftColumn={
              <BreakoutBox transparentmobile>
                {!step ? (
                  <p>Something went wrong fetching your move data</p>
                ) : (
                  <TaskList moveId={move.id} tasks={step?.tasks} />
                )}
              </BreakoutBox>
            }
            rightColumn={
              <>
                <TextBlock
                  title="What to expect"
                  text={`This is where it becomes official. Your solicitor and the ${
                    isBuying ? 'seller' : 'buyer'
                  }'s solicitor will swap signed copies of the contract. This makes it a legally-binding contract and you really start to celebrate.`}
                />
                {!!participant && <ContactCard cardTitle="Your conveyancer" {...participant} />}
              </>
            }
          />
        }
        isComplete={step?.progress === 100}
        styling={{
          backgroundcolour: agency.colour,
        }}
      />
    </>
  );
};
