// Global
import { useSelector } from 'react-redux';

// Components
import { TaskList } from '@components/TaskList';
import { ContactCard } from '@components/ContactCard';
import { ConnectedPill } from '@components/ConnectedPill';
import { ContentWrapper } from '@components/WizardContentBlocks';
import { TextBlock } from '@components/WizardContentBlocks';
import { WizardTemplate } from '@components/WizardTemplate';
import { BreakoutBox } from '@components/WizardContentBlocks';

// Constants
import { WizardPaths } from '@constants/index';

// Images
import { Conveyancing as ConveyancingIcon } from '@images/icons';

// Redux
import { selectMove } from '@features/move/move';
import { selectMoveStages } from '@features/moveStages/moveStages';
import { MoveStepIds } from '@features/moveStages/moveStages.types';

// Utils
import { Agencies, getAgencyStyling } from '@utils/agencyStyling';
import { isBuyer } from '@utils/checkBuyerSeller';
import { BuyerProtectionSignpost, SellerProtectionSignpost } from '@components/Signpost';
import { useIsTaskCompleted } from '@hooks/useTaskChecks';

export const ConveyancingPage = () => {
  const { value: move } = useSelector(selectMove);
  const {
    value: { steps },
  } = useSelector(selectMoveStages);
  const isBuying = isBuyer(move.type);

  const hideBuyerSignpost = useIsTaskCompleted(MoveStepIds.Contracts, 'DRAFT_CONTRACT_RECEIVED');

  const hideSellerSignpost = useIsTaskCompleted(MoveStepIds.Contracts, 'CONTRACT_DRAFTED');

  const participant = move?.participants.find(
    participant => participant.type === 'CONVEYANCER'
  ) as IAgentParticipant;

  const step = steps.find(step => step.id === MoveStepIds.Conveyancing);

  const agency = getAgencyStyling(!!participant ? participant?.agency.code : Agencies.DEFAULT);

  return (
    <WizardTemplate
      move={move}
      prevHref={`/wizard/${isBuying ? WizardPaths.finance : WizardPaths.saleAgreed}`}
      nextHref={`/wizard/${WizardPaths.signContracts}`}
      totalStepCount={isBuying ? 11 : 10}
      index={isBuying ? 2 : 1}
      title="Conveyancing"
      icon={<ConveyancingIcon width="76px" />}
      intro={
        !!participant && (
          <ConnectedPill companyName={participant.agency.name} image={agency.images.pill} />
        )
      }
      children={
        <ContentWrapper
          manualStepUserType={step?.tracking === 'MANUAL' ? 'conveyancer' : ''}
          percentageComplete={step?.progress || 0}
          leftColumn={
            <>
              {!hideBuyerSignpost && isBuying && <BuyerProtectionSignpost />}
              {!hideSellerSignpost && !isBuying && <SellerProtectionSignpost />}
              <BreakoutBox transparentmobile>
                {!step ? (
                  <p>Something went wrong fetching your move data</p>
                ) : (
                  <TaskList moveId={move.id} tasks={step?.tasks} />
                )}
              </BreakoutBox>
            </>
          }
          rightColumn={
            <>
              <TextBlock
                title="What to expect"
                text="Home puts you in control of your move. You can see progress of each conveyancing step here. If your conveyancer is connected to Home they can share all key documents through messaging. Top tip - ask your conveyancer to request local area searches asap as they can take more than ten days in certain areas."
              />
              {!!participant && <ContactCard cardTitle="Your conveyancer" {...participant} />}
            </>
          }
        />
      }
      isComplete={step?.progress === 100}
      styling={{
        backgroundcolour: agency.colour,
      }}
    />
  );
};
