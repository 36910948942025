// Global
import { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

// Components
import { PageLoading } from '@components/PageLoading';

// Templates
import { Error } from '@templates/Error';

// Images
import { ReactComponent as HomeLogo } from '@images/logo.svg';

// Hooks
import { usePost } from '@hooks/usePost';

// Constants
import { ApiRoutes } from '@constants/index';

export const ForgotPasswordConfirm = () => {
  const code = new URLSearchParams(window.location.search).get('code') || '';
  const { postData, statusCode, serverError, isSuccess } = usePost();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        await postData(ApiRoutes.CONFIRM_FORGOT_PASSWORD, { code });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [code]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return <PageLoading />;
  }

  if (statusCode === 400 || !code) {
    return (
      <Error
        title="Invalid Code"
        text="The link you visited might have expired. You can refresh the page to try again, or go back to reset password"
        alternativeLink={{
          text: 'Back to forgot password',
          action: () => navigate('/forgot-password'),
        }}
      />
    );
  }

  if (serverError) {
    return (
      <Error
        title="Something went wrong"
        text="You can refresh the page to try again, or go back to reset password"
        alternativeLink={{
          text: 'Back to forgot password',
          action: () => navigate('/forgot-password'),
        }}
      />
    );
  }

  if (isSuccess) {
    return (
      <div className="flex min-h-screen w-screen justify-center bg-brand">
        <main className="mx-auto mt-16 flex w-full max-w-xl flex-col items-center gap-10 bg-brand">
          <HomeLogo className="w-[93px] fill-white" />

          <h1 className="text-center text-4xl font-medium text-white">Confirmed Forgot Password</h1>

          <p className="text-center text-white">
            Thank you, your password reset request has been confirmed. Please check your email for
            instructions on how to set a new password.
          </p>
        </main>
      </div>
    );
  }

  // If all else fails go back to signin
  return <Navigate to="/signin" />;
};

export default ForgotPasswordConfirm;
