// Global
import { useNavigate, Link } from 'react-router-dom';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FieldValues, useForm } from 'react-hook-form';

// Components
import { Button } from '@components/Button';
import { ErrorMessage } from '@components/ErrorMessage';
import { Input } from '@components/Input';

// Constants
import { ApiRoutes } from '@constants/index';

// Hooks
import { usePost } from '@hooks/usePost';

// Images
import DefaultImage from '@images/default_image_05-2024.webp';
import { HomeLogo } from '@components/HomeLogo';

const FORM_SCHEMA = Yup.object().shape({
  emailAddress: Yup.string().email('Invalid email address').required('Email is required'),
  password: Yup.string().required('Password is required'),
});

const ERROR_MAP: any = {
  'BAD-LOGIN': 'No matching email and password found.',
  'BAD-USER': 'User does not exist.',
  'BAD-PASSWORD': 'Password was incorrect.',
  'RESET-IN-PROGRESS': (
    <>
      Password reset is in progress. Please check your email for a forgotten password link or start
      again by going to{' '}
      <Link className=" font-semibold underline hover:no-underline" to="/forgot-password">
        Reset password
      </Link>
      .
    </>
  ),
  LOCKED: 'Account is locked.',
  REJECTED: 'Sign in attempt has been rejected.',
};

export const SignIn = () => {
  const {
    register,
    handleSubmit,
    formState: { isValid, isSubmitting },
    reset,
  } = useForm({
    resolver: yupResolver(FORM_SCHEMA),
  });
  const { postData, serverError } = usePost();
  const navigate = useNavigate();

  const onSubmit = async (data: FieldValues) => {
    const response = await postData(
      ApiRoutes.SIGNIN,
      { emailAddress: data.emailAddress, password: data.password },
      {
        headers: {
          'content-type': 'application/json',
          'Home-Api-version': '2023-07-01',
        },
      }
    );

    if (response?.status === 200) {
      navigate('/');
    } else {
      reset({ emailAddress: data?.emailAddress, password: '' });
    }
  };

  return (
    <div className="box-border flex min-h-screen w-full items-center justify-center p-4 lg:p-6">
      <main className="grid w-full max-w-xl grid-cols-1 overflow-hidden rounded-3xl bg-white shadow-homeLarge lg:max-w-7xl lg:grid-cols-2">
        <div
          className="col-span-1 hidden bg-cover bg-right bg-no-repeat lg:block"
          style={{ backgroundImage: `url(${DefaultImage})` }}
        ></div>
        <div className="col-span-1 flex flex-col items-center gap-6 p-10">
          <HomeLogo red />

          <h1 className="mt-3 text-center text-4xl font-medium">Sign in</h1>

          <form
            className="flex w-full flex-col items-center justify-center gap-10"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Input {...register('emailAddress')} type="text" id="email" label="Email" />

            <Input {...register('password')} type="password" id="password" label="Password" />

            <div className="w-full">
              <p>
                Trouble signing in?{' '}
                <Link to="/forgot-password" className="underline hover:no-underline">
                  Reset Password
                </Link>
              </p>
            </div>

            <Button
              disabled={!isValid || isSubmitting}
              isSubmitting={isSubmitting}
              customStyles="!my-0"
            >
              Let's go
            </Button>
          </form>

          {!!serverError && (
            <ErrorMessage
              includeHomeRunnerContact={
                !['BAD-LOGIN', 'BAD-USER', 'BAD-PASSWORD', 'RESET-IN-PROGRESS'].includes(
                  serverError
                )
              }
            >
              {serverError in ERROR_MAP ? ERROR_MAP[serverError] : ERROR_MAP['BAD-LOGIN']}
            </ErrorMessage>
          )}

        </div>
      </main>
    </div>
  );
};
