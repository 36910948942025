// Global
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Components
import { ContactCard } from '@components/ContactCard';
import { ContentWrapper } from '@components/WizardContentBlocks';
import { TextBlock } from '@components/WizardContentBlocks';
import { WizardTemplate } from '@components/WizardTemplate';
import { ConnectedPill } from '@components/ConnectedPill';
import { TaskList } from '@components/TaskList';
import { BreakoutBox } from '@components/WizardContentBlocks/BreakoutBox';
import { WizardPaths } from '@constants/index';

// Images
import { Pound } from '@images/icons';

// Redux
import { selectMove } from '@features/move/move';
import { selectMoveStages } from '@features/moveStages/moveStages';
import { MoveStepIds } from '@features/moveStages/moveStages.types';

// Utils
import { isBuyer } from '@utils/checkBuyerSeller';
import { Agencies, getAgencyStyling } from '@utils/agencyStyling';

export const FinancePage = () => {
  const { value: move } = useSelector(selectMove);
  const {
    value: { steps },
  } = useSelector(selectMoveStages);
  const isBuying = isBuyer(move.type);

  if (!isBuying) return <Navigate to={`/wizard/${WizardPaths.conveyancing}`} />;

  const participant = move?.participants.find(
    participant => participant.type === 'BROKER'
  ) as IAgentParticipant;

  const step = steps.find(step => step.id === MoveStepIds.Finances);

  const agency = getAgencyStyling(!!participant ? participant.agency.code : Agencies.DEFAULT);

  return (
    <WizardTemplate
      move={move}
      prevHref={`/wizard/${WizardPaths.findAProperty}`}
      nextHref={`/wizard/${WizardPaths.conveyancing}`}
      totalStepCount={isBuying ? 11 : 10}
      index={1}
      title="Finances"
      intro={
        !!participant && (
          <ConnectedPill companyName={participant.agency.name} image={agency.images.pill} />
        )
      }
      icon={<Pound height="62px" />}
      children={
        <ContentWrapper
          manualStepUserType={step?.tracking === 'MANUAL' ? 'broker' : ''}
          percentageComplete={step?.progress || 0}
          leftColumn={
            <BreakoutBox transparentmobile>
              {!step ? (
                <p>Something went wrong fetching your move data</p>
              ) : (
                <TaskList moveId={move.id} tasks={step?.tasks} />
              )}
            </BreakoutBox>
          }
          rightColumn={
            <>
              <TextBlock
                title="What to expect"
                text="When you're buying a home, the mortgage process can seem complex and overwhelming, but it doesn't have to be. Your mortgage broker is on hand to guide you through the process."
              />
              {!!participant && <ContactCard cardTitle="Your broker" {...participant} />}
            </>
          }
        />
      }
      isComplete={step?.progress === 100}
      styling={{
        backgroundcolour: agency.colour,
      }}
    />
  );
};
